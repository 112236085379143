/* unplugin-vue-components disabled */import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '@/assets/images/icon/edit.svg';
import _imports_1 from '@/assets/images/icon/plus-btn.svg';
var _hoisted_1 = {
  class: "text-edit-control"
};
var _hoisted_2 = ["innerHTML", "contenteditable"];
var _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: "",
  srcset: ""
};
var _hoisted_4 = {
  key: 1,
  src: _imports_1,
  alt: "",
  srcset: ""
};
export default {
  __name: 'TextEditControl',
  props: {
    modelValue: null
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var cData = reactive({
      contenteditable: false,
      hasContent: props.modelValue ? true : false
    });
    var refContent = ref(null);

    function setCaret() {
      var el = refContent.value;
      var range = document.createRange();
      var sel = window.getSelection();
      var childNodes = el.childNodes; // 空欄位，無須設定caret位置

      if (!childNodes.length) return;
      range.setStart(el.childNodes[0], el.childNodes[0].length);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }

    function toggleEdit() {
      cData.contenteditable = true;
      setCaret();
      nextTick(function () {
        refContent.value.focus();
      });
    }

    function checkStatus() {
      if (refContent.value.textContent) {
        cData.hasContent = true;
      } else {
        cData.hasContent = false;
      }
    }

    function onBlur() {
      checkStatus();
      cData.contenteditable = false;
      emit('update:modelValue', refContent.value.textContent);
    }

    watch(function () {
      return props.cotnent;
    }, function () {
      checkStatus();
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "text-edit-control__content",
        ref_key: "refContent",
        ref: refContent,
        innerHTML: __props.modelValue,
        contenteditable: _unref(cData).contenteditable,
        onBlur: onBlur
      }, null, 40, _hoisted_2), _createElementVNode("div", {
        class: "text-edit-control__tool",
        onClick: toggleEdit
      }, [!_unref(cData).contenteditable && _unref(cData).hasContent ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : _createCommentVNode("", true), !_unref(cData).contenteditable && !_unref(cData).hasContent ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : _createCommentVNode("", true)])]);
    };
  }
};